<template>
    <div class="mind-box">
    <x-content v-if="loading" style="height:100%;" :contentStyle="{alignItems:'center',justifyContent:'center',height:'100%'}">
      <p>总结中，请稍等</p>
      <bounce-loading></bounce-loading>
    </x-content>
    <div v-else>
  <div class="data_content" >
    <h1 class="gradient-text">技术关键事件总结</h1>

    <el-input placeholder="请输入技术关键事件总结关键词" v-model="query" style="width: 50%;margin: 0 auto;display: table;">
    <el-button type="success" slot="append" icon="el-icon-search" @click="getInfo">  总结</el-button>
    </el-input>
  <p v-html="thinkContent" class="thinkContent" ref="thinkContent"></p>
  <div class="loading-container" v-show="thinkLoading">
              <img
                src="/static/img/chatLoading.png"
                alt="Loading"
                class="loading-image"
              />
              <div class="loading-text">...</div>
            </div>
  <div class="Timeline_container">
    <el-timeline>
    <el-timeline-item placement="top" class="custom-timeline-item"
      v-for="(obj, index) in timelineData"
      :key="index"
      :timestamp="obj['年份']">
      <p 
            v-for="(event, eIndex) in obj['技术关键事件']"
            :key="eIndex"
           
          >
            {{ event['具体时间']+event['标题'] }}
          </p>
    </el-timeline-item>
  </el-timeline>
  </div>

</div>

</div>
  </div>
  
</template>

<script>
export default {
  name: 'TechnicalSummary',
  data() {
    return {
      thinkContent:'',
      loading:false,
      thinkLoading:false,
      query:'',
      timelineData: ''
        
      
    };
  },
  mounted() {
    this.setDocumentTitle('技术关键事件总结')

  },
  methods: {
     getInfo() {
        this.thinkContent = ''
        this.timelineData=''

        this.loading=true
        this.postPowerJSON('/power', 
        {
            inputs: {},
            query:this.query,
            response_mode: "streaming",
            conversation_id: "",
            user: "buxihuo"
        },
          {
            timeout: 300000,
            onStream: (data) => { // 流式数据回调
              const jsonString = JSON.stringify(data);
              if(!jsonString.includes("event: ping")){
                var partialData= JSON.parse(JSON.parse(jsonString));
                if(partialData.event){
                  if(partialData.event=="agent_thought"){                
                    this.thinkLoading=true
                  }else{
                    this.thinkLoading=false
                  }
                  if(partialData.event=="agent_message"){                
                    const d=partialData.answer?partialData.answer:''
                    if(d){
                      this.loading=false
                    }
                    if(d.includes("[\n")){
                      this.timelineData =this.convertToArray(d)
                    }else{
                      this.thinkContent+=d
                      this.scrollToBottom()
                    
                    }
              }
            }
          }
        }
          })
          .then(finalData => {
            //console.log('完整数据:', finalData);
          })
          .catch(error => {
            console.error('请求失败:', error);
          });
    },
    convertToArray(originalData) {
      var data=originalData
      if(typeof originalData=='string'){
         data=JSON.parse(originalData)
      }
      const processedData = data.action_input || data;
      processedData.forEach(year => {
          // 按具体时间升序排序
          year.技术关键事件.sort((a, b) => 
            a.具体时间.localeCompare(b.具体时间)
          )
          
          // 处理日期格式
          year.技术关键事件.forEach(event => {
            event.具体时间 = event.具体时间.substring(5) 
          })
        })
        this.timelineData = processedData
        return  this.timelineData
    },
  scrollToBottom() {
      this.$nextTick(() => {
        const thinkContent = this.$refs.thinkContent;
        thinkContent.scrollTop = thinkContent.scrollHeight;
      });
    },
  postPowerJSON(url, params, config = {}) {
  return new Promise((resolve, reject) => {
    const fullUrl = new URL(url, location.href).href;
    const controller = new AbortController();
    const timeoutId = setTimeout(() => {
      controller.abort();
      reject(new Error('请求超时'));
    }, config.timeout || 600000);

    fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer app-mKSNqpD3EqoGvMmgbGTWTZwR',
        ...config.headers,
      },
      body: JSON.stringify(params),
      signal: controller.signal,
    })
      .then(async (response) => {
        clearTimeout(timeoutId);
        if (!response.ok) throw new Error(`HTTP 错误! 状态码: ${response.status}`);

        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let buffer = ''; // 缓冲区保存未处理的数据
        let accumulatedData = ''; // 累积所有事件的完整数据

        try {
          while (true) {
            const { done, value } = await reader.read();
            const chunk = decoder.decode(value, { stream: !done });

            if (chunk) {
              // 处理每行，移除行首的 data: 前缀
              const processedChunk = chunk.split('\n')
                .map(line => line.replace(/^data:\s*/, ''))
                .join('\n');
              buffer += processedChunk;

              // 分割完整事件（基于双换行符）
              const eventParts = buffer.split('\n\n');
              const completeEvents = eventParts.slice(0, -1);
              buffer = eventParts.pop() || ''; // 剩余部分放回缓冲区

              // 处理每个完整事件
              completeEvents.forEach(eventData => {
                // 合并事件内的换行（根据需求调整）
                const data = eventData.replace(/\n/g, '');
                accumulatedData += data;
                if (config.onStream) config.onStream(data);
              });
            }

            if (done) {
              // 处理缓冲区剩余数据
              if (buffer) {
                const data = buffer.replace(/\n/g, '');
                accumulatedData += data;
                if (config.onStream) config.onStream(data);
              }
              resolve(accumulatedData);
              break;
            }
          }
        } catch (streamError) {
          reject(streamError);
        }
      })
      .catch((error) => {
        clearTimeout(timeoutId);
        reject(error);
      });
  });
}
  }
};
</script>

<style >

.mind-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 4px;
  overflow: auto;
}
.mind-box::-webkit-scrollbar{
		width: 6px;
	}
  .mind-box::-webkit-scrollbar-thumb{
		border-radius: 10px;
		-webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.6);
		opacity: 0.2;
		background: fade(#8398de, 90%);
	}
  .data_content{
    padding: 20px !important;
}
  .thinkContent,.thinkContent details{
  line-height: 26px !important;
  margin-bottom: 10px;

}
.timeline-container {
  width: 90%;
  margin: 40px auto;
  padding: 60px 20px;
  background-color: #fff;
}


.Timeline_container {
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 10px;
    margin-top: 30px;
}
/* 调整时间轴布局 */
.el-timeline {
  padding-left: 100px; /* 为时间戳留出空间 */
}

.custom-timeline-item .el-timeline-item__timestamp {
  position: absolute;
  left: -70px; /* 调整时间戳位置 */
  text-align: right;
  padding-right: 20px;
  color: #666;
  font-size: 14px;
    color: #409eff;
    font-weight: bold;
    left: -136px;
    padding: 3px 0 0;
    margin: 0;
    width: 120px;
}
.el-timeline-item__content p{
  margin: 0px;
  margin-bottom: 10px;
}
.loading-container {
  display: flex;
  align-items: center; /* 垂直居中 */
  font-family: Arial, sans-serif;
}
.loading-text {
  margin-left: 3px; /* 文字与图片的间距 */
  font-size: 14px;
  color: #666;
}
.loading-image {
  width: 30px; /* 图片大小 */
  height: 30px; /* 图片大小 */
  border-radius: 50%; /* 使图片变成圆形（可选） */
  animation: spin 1s linear infinite; /* 应用旋转动画 */
}
/* 定义旋转动画 */
@keyframes spin {
  0% {
    transform: rotate(0deg); /* 起始角度 */
  }
  100% {
    transform: rotate(360deg); /* 结束角度 */
  }
}

.gradient-text {
  /* 基础样式 */
  font-size: 48px;
  letter-spacing: 5px;
  text-align: center;
  margin-bottom: 30px;
  background-image: linear-gradient(246deg,#856efa,#1366ec 64%,#5c99ff 99%);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}
</style>